import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaPython } from "react-icons/fa";
import { MdBugReport } from "react-icons/md";
import { SiJavascript, SiMysql } from "react-icons/si";
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
       <Col xs={4} md={2} className="tech-icons">
      <a href="https://www.python.org" target="_blank" rel="noopener noreferrer">
        <FaPython />
      </a>
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noopener noreferrer">
        <SiJavascript />
      </a>
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <a href="https://www.mysql.com" target="_blank" rel="noopener noreferrer">
        <SiMysql />
      </a>
    </Col>
    <Col xs={4} md={2} className="tech-icons">
      <a href="https://hackerone.com" target="_blank" rel="noopener noreferrer">
        <MdBugReport />
      </a>
    </Col>
    </Row>  
  );
}

export default Toolstack;
