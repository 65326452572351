import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Portfolio from "../../Assets/Projects/Portfolio.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Portfolio}
              isBlog={false}
              title="My Personal Portfolio"
              description="Everything You Need To Know About Me Is Listed In Here"
              // ghLink="https://github.com/Dev-Khrish"
              demoLink="https://khrish.tech"
            />
          </Col>
        </Row>
        <h1 style={{ color: "white" }}>Visit <strong className="purple">Github </strong> To See More</h1>
      </Container>
    </Container>
  );
}

export default Projects;
