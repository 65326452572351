import React from "react";
import { Col, Row } from "react-bootstrap";
import { 
  FaWindows,
  FaGithub,
  FaSpotify,
  FaDiscord,
} from "react-icons/fa";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiKalilinux
} from "react-icons/si";
import {
  PiTelegramLogo,
} from "react-icons/pi"

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
  <a href="https://www.microsoft.com/windows" target="_blank" rel="noopener noreferrer">
    <FaWindows />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://www.kali.org" target="_blank" rel="noopener noreferrer">
    <SiKalilinux />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://code.visualstudio.com" target="_blank" rel="noopener noreferrer">
    <SiVisualstudiocode />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://github.com" target="_blank" rel="noopener noreferrer">
    <FaGithub />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://www.spotify.com" target="_blank" rel="noopener noreferrer">
    <FaSpotify />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
    <FaDiscord />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
    <PiTelegramLogo />
  </a>
</Col>

    </Row>  
  );
}

export default Toolstack;
