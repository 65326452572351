import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaJava,
  FaLinux,
  FaDiscord,
  FaGitAlt,
} from "react-icons/fa";
import {
  MdHtml,
  MdCss,
  MdOutlineSecurity,
} from "react-icons/md";
import { 
  SiMicrosoftazure,
  SiAdobepremierepro,
  
} from "react-icons/si";
import { 
  PiTelegramLogo,
} from "react-icons/pi";
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
     <Col xs={4} md={2} className="tech-icons">
  <a href="https://www.java.com" target="_blank" rel="noopener noreferrer">
    <FaJava />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://developer.mozilla.org/en-US/docs/Web/HTML" target="_blank" rel="noopener noreferrer">
    <MdHtml />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" target="_blank" rel="noopener noreferrer">
    <MdCss />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://azure.microsoft.com" target="_blank" rel="noopener noreferrer">
    <SiMicrosoftazure />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://www.linux.org" target="_blank" rel="noopener noreferrer">
    <FaLinux />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://www.adobe.com/products/premiere.html" target="_blank" rel="noopener noreferrer">
    <SiAdobepremierepro />
  </a>
</Col>
<Col xs={4} md={2} className="tech-icons">
  <a href="https://git-scm.com/" target="_blank" rel="noopener noreferrer">
    <FaGitAlt />
  </a>
</Col>

    </Row>
  );
}

export default Techstack;
